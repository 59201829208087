<template>
  <iframe
    ref="player"
    :key="url"
    :src="url"
    allow="autoplay"
    width="100%"
    height="100%"
    frameborder="0"
    loading="lazy"
    allowfullscreen
    allowtransparency
    crossorigin
    playsinline
  ></iframe>
</template>

<script>
export default {
  name: 'YoutubePlayer',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  mounted () {
    if (!window.YT || !document.getElementById('iframe-yt-script')) {
      const firstScriptTag = document.getElementsByTagName('script')[0]
      const tag = document.createElement('script')
      const callback = window.onYouTubeIframeAPIReady

      tag.id = 'iframe-yt-script'
      tag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      // Set callback to process queue
      window.onYouTubeIframeAPIReady = () => {
        // Call global callback if set
        if (callback) {
          callback()
        }
        this.createPlayer()
      }
    } else {
      this.createPlayer()
    }
  },
  methods: {
    createPlayer () {
      if (!window.YT) return
      this.player = new window.YT.Player(this.$refs.player, {
        events: {
          onReady: () => this.$emit('on-ready'),
          onStateChange: (e) => this.onPlayerStateChange(e.data)
        }
      })
    },
    onPlayerStateChange (playerStatus) {
      if (playerStatus === -1) {
        // unstarted
      } else if (playerStatus === 0) {
        // ended
        this.$emit('end')
      } else if (playerStatus === 1) {
        // playing
        this.$emit('playing')
      } else if (playerStatus === 2) {
        // paused
        this.$emit('pause')
      } else if (playerStatus === 3) {
        // buffering
      } else if (playerStatus === 5) {
        // video cued
      }
    },
    play () {
      this.player.playVideo()
    },
    pause () {
      this.player.pauseVideo()
    },
    mute () {
      this.player.mute()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
