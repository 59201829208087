<template>
  <video
    :src="url"
    :poster="poster ? `/media/${poster}` : null"
    :muted="muted"
    :autoplay="autoplay"
    :controls="controls"
    :loop="loop"
    ref="video"
    width="100%"
    height="100%"
    preload="auto"
    crossorigin
    playsinline
    @playing="$emit('playing')"
    @pause="$emit('pause')"
    @ended="$emit('ended')"
  />
</template>

<script>
export default {
  name: 'VideoFilePlayer',
  props: {
    url: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: false
    },
    muted: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    controls: {
      type: Boolean,
      required: false,
      default: true
    },
    loop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
video {
  outline: none;

  &[poster] {
    object-fit: cover;
  }
}
</style>
