import { render, staticRenderFns } from "./VideoFilePlayer.vue?vue&type=template&id=d871e866&scoped=true&"
import script from "./VideoFilePlayer.vue?vue&type=script&lang=js&"
export * from "./VideoFilePlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoFilePlayer.vue?vue&type=style&index=0&id=d871e866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d871e866",
  null
  
)

export default component.exports