<template>
  <v-responsive v-if="!!videoSettings" :aspect-ratio="ratio">
    <component
      ref="video"
      v-on="$listeners"
      :is="videoSettings.component"
      :url="videoSettings.url"
      :poster="poster"
      :loop="loop"
      :muted="muted"
      :autoplay="autoplay"
      :controls="controls"
      :class="{ 'virtual-tour-video': $route.name === 'VirtualTourPage' }"
    />
  </v-responsive>
</template>

<script>
import VideoFilePlayer from '@/atoms/VideoFilePlayer.vue'
import YoutubePlayer from '@/atoms/YoutubePlayer.vue'
import VimeoPlayer from '@/atoms/VimeoPlayer.vue'

const YT_REGEX = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i // eslint-disable-line
const VIMEO_REGEX = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/ // eslint-disable-line

export default {
  name: 'VideoPlayer',
  props: {
    ratio: {
      type: Number,
      default: 1.778
    },
    video: {
      type: [String, Object],
      required: true
    },
    poster: {
      type: String,
      required: false
    },
    muted: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    controls: {
      type: Boolean,
      required: false,
      default: true
    },
    loop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    videoSettings () {
      if (this.video.id) {
        return { component: VideoFilePlayer, url: '/media/' + this.video.id }
      } else if (this.video.indexOf('vimeo') !== -1) {
        return { component: VimeoPlayer, url: 'https://player.vimeo.com/video/' + this.video.match(VIMEO_REGEX)[3] }
      } else if (this.video.match(YT_REGEX)[1]) {
        return { component: YoutubePlayer, url: 'https://www.youtube.com/embed/' + this.video.match(YT_REGEX)[1] + '?enablejsapi=1&playsinline=1&modestbranding=1&iv_load_policy=3' }
      }
      return null
    }
  },
  methods: {
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-responsive {
  @include breakpoint ('sm-only') {
    max-width: 740px;
    margin: 0 auto;
  }
}

.virtual-tour-video {
  position: absolute;
  width: auto;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
