<template>
  <iframe
    ref="player"
    :key="url"
    :src="url"
    type="text/html"
    width="100%"
    height="100%"
    frameborder="0"
    loading="lazy"
    allowfullscreen
    allowtransparency
    crossorigin
    playsinline
  ></iframe>
</template>

<script>
export default {
  name: 'VimeoPlayer',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  mounted () {
    if (!window.Vimeo || !document.getElementById('iframe-vimeo-script')) {
      const firstScriptTag = document.getElementsByTagName('script')[0]
      const tag = document.createElement('script')
      const callback = tag.onload

      tag.id = 'iframe-vimeo-script'
      tag.src = 'https://player.vimeo.com/api/player.js'
      tag.onload = () => {
        if (callback) {
          callback()
        }
        this.createPlayer()
      }

      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      this.createPlayer()
    }
  },
  beforeDestroy () {
    this.player && this.player.destroy()
  },
  methods: {
    createPlayer () {
      if (!this.$refs.player) return
      this.player = new window.Vimeo.Player(this.$refs.player)
      this.player.on('play', () => this.$emit('playing'))
      this.player.on('pause', () => this.$emit('pause'))
      this.player.on('ended', () => this.$emit('ended'))
    },
    pause () {
      this.player.pause()
    }
  }
}
</script>

<style>

</style>
